.popover__container {
    flex-direction: column;
    position: relative;
    top: 16px;
    display: none;
}

.popover__container.open {
    display: flex;
}

.popover__menu-container {
    position: absolute;
    z-index: 10;

    padding: 16px;
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;

    top: 100%;

    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgba(171, 171, 171, 0.25);
}

.popover__menu-container.left {
    left: -16px;
}

.popover__menu-container.right {
    right: 0;
}

.popover__arrow-container {
    position: relative;
    z-index: 11;
}

.popover__arrow {
    position: absolute;
    right: 0;
    z-index: 1;

    /* Border */
    background-color: #FFFFFF;
    border-left: 1px solid #DCDCDC;
    border-top: 1px solid #DCDCDC;
    transform: translate(-50%, -50%) rotate(45deg);

    /* Size */
    height: 16px;
    width: 16px;
}