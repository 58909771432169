.input-root {
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    font-size: 1rem;
    font-family: Roboto;
    line-height: 1.1875em;
    align-items: center;

    box-sizing: border-box;
    height: 29px;
    width: 96px;
    border: 1px solid #DCDCDC;
    border-radius: 1px;
}

.kraken-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 6px 12px 7px 8px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    font-size: 14px;
    text-overflow: ellipsis;
}

.kraken-input::placeholder {
    color: #BBBBBB;
    opacity: 1;
}

.date-range__input-wrapper .kraken-input {
    border-radius: 4px;
    border: 1px solid #DCDCDC;
    padding: 6px 10px;
    line-height: 18px;
}

.date-range__input-wrapper .kraken-input:focus::placeholder {
    visibility: hidden;
}

.date-range__input-wrapper .kraken-input:focus {
    border: 1px solid #FFB800;
}

.date-range__input-wrapper .kraken-input:focus ~ .date-range__input-mask {
    display: block;
}

.date-range__input-mask {
    position: absolute;
    display: none;
    font-size: 14px;
    line-height: 18px;
    color: #828282;
    left: 10px;
}

.date-range__input-mask .input-value {
    color: transparent;
}

.kraken-input:focus {
    outline: none;
}

.kraken-input__popper {
    width: 319px;
    padding: 12px;
    visibility: hidden;
    z-index: 999;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: normal;

    color: #1E1E1E;

    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgb(171 171 171 / 25%);
}

.kraken-input__popper .kraken-input__popper-arrow {
    position: absolute;
    left: 19px;
    top: 0px;
    z-index: 1;
    background-color: #FFFFFF;
    border-left: 1px solid #DCDCDC;
    border-top: 1px solid #DCDCDC;

    transform: translate(-50%, -50%) rotate(45deg);
    height: 16px;
    width: 16px;
}

.kraken-input__popper.kraken-input__popper_display {
    visibility: visible;
}