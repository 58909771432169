.cell-time-ago__popover {
    visibility: hidden;
    padding: 16px;
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgb(171 171 171 / 25%);
}

.cell-time-ago__popover_display {
    visibility: visible;
}

.cell-time-ago__popover-arrow {
    position: absolute;
    left: 20px;
    top: 0;
    z-index: 1;
    background-color: #FFFFFF;
    border-left: 1px solid #DCDCDC;
    border-top: 1px solid #DCDCDC;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 16px;
    width: 16px;
}