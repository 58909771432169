.magnet-legend {
    width: 300px;
}

.magnet-legend-popover {
    margin-left: 8px;
}

.magnet-legend_hidden {
    display: none;
}

.magnet-legend__item {
    padding-bottom: 8px;
}

.magnet-legend__item:last-child {
    padding-bottom: 0;
}

.legend-value .magnet-legend__item span{
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0em;
    color: #233232;
}

.legend-value .magnet-legend__item b{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0em;
}