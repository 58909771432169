*, *:before, *:after {
    box-sizing: inherit; }

* {
    pointer-events: auto !important;
}

iframe {
    border: none;
}

html {
    box-sizing: border-box;
    overflow: hidden;
    -webkit-font-smoothing: antialiased; }
html body {
    margin: 0;
    padding: 0;
    font-family: sans-serif; }

html, body, #root,
.App,
.App > .app-root {
    height: 100%; }

.app-root {
    display: flex;
}

.app-root-body {
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    height: 100%;
    width: 100%;
    margin-left: 0;
    z-index: 11;
    transition: all 0.2s ease-in-out;
}

.app-root-scroll {
    width: 100%;
}

.app-root__table-container {
    margin: 0 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.app-root__table {
    height: 100%;
}

.highlight-text {
    background: rgba(255, 184, 0, 0.3);
    color: #212121;
}

.google-login-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}