.account-popup {
    position: absolute;
    min-width: 189px;
    left: -12px;
    bottom: calc(100% - 16px);
    display: none;
    background: #2B2B2B;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    color: #fff;
    z-index: 10000;
}

.account-popup .user-info {
    padding: 12px 0 8px;
    margin: 0 16px;
    border-bottom: 1px solid #363636;
    cursor: default;
}

.account-popup.open {
    display: block;
}

.account-popup .email {
    font-size: 12px;
    line-height: 18px;
}

.account-popup .user-name {
    margin-bottom: 4px;
}

.account-popup .logout-button {
    display: flex;
    padding: 0 16px;
    margin: 4px 0 8px;
    height: 32px;
    align-items: center;
}

.account-popup .logout-button:hover {
    background: #363636;
    color: #FFB800;
}

.account-popup .logout-button:hover .logout-icon {
    background: url(../../../../../css/img/logout-icon-yellow.svg) center no-repeat no-repeat;
}

.account-popup .logout-icon {
    width: 24px;
    height: 24px;
    background: url(../../../../../css/img/logout-icon.svg) center no-repeat no-repeat;
    margin-left: 8px;
}

.account-popup .logout-icon:before {
    background: url(../../../../../css/img/logout-icon-yellow.svg) center no-repeat no-repeat;
    width: 0;
    height: 0;
    visibility: hidden;
}