.popup__wrapper {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}
.popup__wrapper.open {
    display: flex;
}

.popup__container {
    font-family: 'Source Sans Pro', sans-serif;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-value__container {
    padding: 40px;
    margin: auto 60px;
    position: relative;
    z-index: 2;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 7px 15px 0 rgb(0 0 0 / 10%);
}

.popup__container .title {
    color: #212121;

    font-size: 32px;
    font-weight: bold;
    letter-spacing: -1.19px;
    line-height: 43px;
    text-align: center;
    margin-bottom: 13px;
}

.popup__container .text {
    color: #212121;
    font-weight: 300;

    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 35px;
}

.popup-value__cancel-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup-value__cancel-button .cancel {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 16px;
    width: 16px;
    background: url(../../../css/img/exit-16-default.svg) no-repeat center center;
    cursor: pointer;
}

.popup-value__cancel-button .cancel:before {
    content: url(../../../css/img/exit-16-hover.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.popup-value__cancel-button .cancel:hover {
    background: url(../../../css/img/exit-16-hover.svg) no-repeat center center;
}

.popup__container .buttons {
    display: flex;
    margin-left: 24px;
    margin-right: 24px;
}

.buttons .apply {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 107px;
    border-radius: 4px;
    background-color: #4CAE46;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.38px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.buttons .cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 60px;
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.38px;
    line-height: 20px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.buttons .cancel:hover {
    background-color: #F4F4F4;

}