.side-menu__container {
    width: 64px;
    min-width: 64px;
    margin-bottom: 40px;
    height: 100%;
    background-color: #1E1E1E;
    z-index: 10;
    transition: all 0.2s ease-in-out;
}

.side-menu__container:hover {
    width: 205px;
    min-width: 205px;
    z-index: 11;
}

.side-menu__container:hover + .app-root-body {
    margin-left: -141px;
    z-index: 10;
}

.side-menu__container.hidden {
    display: none;
}

.side-menu__title {
    display: flex;
    align-items: center;
    height: 80px;
    width: 40px;
    border-bottom: 1px solid #363636;
    margin-left: 12px;
}

.side-menu__container:hover .side-menu__title {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
}

.side-menu__account-container {
    display: flex;
    align-items: center;
    height: 64px;
    width: 40px;
    border-top: 1px solid #363636;
    margin-left: 12px;
    position: relative;
    cursor: pointer;
}

.side-menu__account-container.account-popup-open .account-icon,
.side-menu__account-container:hover .account-icon {
    background: url(../../../css/img/account-icon-yellow.svg) center no-repeat no-repeat;
}

.side-menu__account-container.account-popup-open > span,
.side-menu__account-container:hover > span {
    color: #FFB800;
}

.side-menu__account-container.account-popup-open .account-button,
.side-menu__account-container:hover .account-button {
    background: url(../../../css/img/points-icon-yellow.svg) center no-repeat no-repeat;
}

.side-menu__container:hover .side-menu__account-container {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
}

.account-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 16px;
    margin-left: 8px;
    background: url(../../../css/img/account-icon.svg) center no-repeat no-repeat;
}

.account-icon:before {
    content: url(../../../css/img/account-icon-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.side-menu__container:hover .account-icon {
    margin-left: 0;
}

.side-menu__account-container > span {
    opacity: 0;
    transition: opacity 0.2s ease-in;
    color: #fff;
    margin-right: 41px;
}

.side-menu__container:hover .side-menu__account-container span {
    opacity: 1;
}

.account-button {
    width: 24px;
    height: 24px;
    background: url(../../../css/img/points-icon.svg) center no-repeat no-repeat;
}

.side-menu__kraken-icon {
    width: 40px;
    min-width: 40px;
    height: 42px;
    cursor: pointer;
    margin-right: 12px;

    background: url(../../../css/img/kraken.svg) center no-repeat no-repeat;
}

.side-menu__container:hover .side-menu__kraken-icon {
    margin-left: -8px;
}

.side-menu__title-name {
    width: 95px;
    height: 20px;
    cursor: pointer;

    background-size: 95px 20px;
    background-image: url(../../../css/img/app-name.svg);
}

.navigation-menu__container {
    height: auto;
    width: 205px;
    margin-top: 10px;
}

.navigation-menu__item {
    display: flex;
    align-items: center;
    padding-right: 24px;
    height: 44px;
    cursor: pointer;
    position: relative;
}

.navigation-menu__item.hidden {
    display: none;
}

.warning-icon {
    width: 4px;
    height: 24px;
    border-radius: 0 2px 2px 0;
    margin-right: 16px;
}

.nested-route .warning-icon {
    margin-right: 56px;
}

.warning-icon.failed {
    background: #FF0000;
}

.warning-icon.warning {
    background: #FFF500;
}

.warning-icon.hidden {
    display: none !important;
}

.nested-route {
    height: 30px;
    background-color: #1E1E1E !important;
    display: none;
}

.side-menu__container:hover .nested-route.visible {
    display: flex;
}

.google-sales-margin {
    margin-bottom: 10px;
}

.nested-route .navigation-menu__item-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.navigation-menu__item.visible-nested .arrow-close-icon {
    transform: rotate(180deg);
}

.side-menu__container:hover .visible-nested .warning-icon {
    background: transparent;
}

.navigation-menu__item.last-databases-item {
    margin-bottom: 21px;
}

.navigation-menu__item.last-databases-item:after {
    content: '';
    width: 40px;
    height: 1px;
    background: #363636;
    position: absolute;
    bottom: -10px;
    left: 12px;
}

.side-menu__container:hover .navigation-menu__item.last-databases-item:after {
    left: 20px;
    width: 165px;
}

.active-route .navigation-menu__item-name {
    color: #FFB800;
}

.navigation-menu__item:hover {
    background-color: #2B2B2B !important;
}

.navigation-menu__item:hover .arrow-close-icon {
    background: url(../../../css/img/arrow-bottom-yellow.svg) center no-repeat no-repeat;
}

.navigation-menu__item:hover .navigation-menu__item-name {
    color: #FFB800;
}

.navigation-menu__item-name {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    opacity: 0;
    transition: opacity 0.2s ease-in;
}

.navigation-menu__icon {
    height: 24px;
    width: 24px;
    margin-right: 16px;
}

.side-menu__container:hover .navigation-menu__item-name {
    opacity: 1;
}

.arrow-close-icon {
    background: url(../../../css/img/arrow-bottom.svg) center no-repeat no-repeat;
    width: 14px;
    height: 6px;
    margin-left: auto;
}