.hover-button-value {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.hover-button__container {
    position: relative;
    width: 91px;
    height: 32px;
    margin: 9px 0;
    display: flex;
    align-items: center;
}

.hover-button__container:hover .hover-button-button {
    display: flex;
}

.hover-button-button {
    position: absolute;
    display: none;
    width: 91px;
    height: 32px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

}