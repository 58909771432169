.subscribe-decide__email-container {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.03em;

    color: #828282;
}

.subscribe-decide__email-container .email {
    height: 20px;
    display: flex;
    align-items: center;
}

.subscribe-decide__email-container .name {
    height: 20px;
    display: flex;
    align-items: center;
}

.subscribe-decide__email-container .company {
    height: 20px;
    display: flex;
    align-items: center;
}

.subscribe-decide__title-message {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    margin-bottom: 16px;
}

.subscribe-decide__container {
    margin-top: 0px !important;
}

.subscribe-decide__filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}

.subscribe-decide__contacts-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
}

.subscribe-decide__contacts-title .approved {
    margin-right: 14px;
}

.subscribe-decide__button.video-ru {

}

.subscribe-decide__button.video-en {
    margin-right: 4px;
}

.subscribe-decide__button .button-root {
    width: 63px;
    height: 40px;
}

.subscribe-decide__button.ignore {
    margin-right: 24px;
}

.subscribe-decide__button.ignore .button-root {
    background-color: #ED5454;
    width: 63px !important;
}

.subscribe-decide__filter {
    margin-bottom: 0 !important;
    height: auto;
    margin-left: 24px;
}

.subscribe-decide__filter.hidden {
    display: none;
}

.clock-icon {
    background: url(../css/img/clock.svg) center no-repeat no-repeat;
    width: 26px;
    height: 25px;
}

.snooze-button {
    margin-right: 0px;
}

.snooze-button .button-root{
    display: flex;
    flex-direction: row-reverse;
    width: 101px;
    background-color: #19AAFC;
}

.snooze-button-icon {
    width: 8px;
    height: 5px;
    margin-left: 8px;
}

.subscribe-decide__table {
    margin-top: 5px;
}

.subscribe-decide__letter {
    height: 100%;
    background-color: #FFFFFF;
}

.subscribe-decide__letter.hidden {
    display: none;
}

.subscribe-decide__letter-title {
    display: flex;
    justify-content: space-between;
    padding: 24px;
}

.subscribe-decide__letter-body {
    border-top: 1px solid #EDEDED;
    padding: 24px;
}

.info__container {
    display: flex;
    flex-direction: column;
}

.info__letter-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: fit-content;
    cursor: pointer;
    text-decoration: none;
}

.info__letter-link .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;

    color: #1E1E1E;
    padding-right: 8px;
}

.info__letter-link .icon {
    background: url(../css/img/magnet-letter.svg) no-repeat center center;
    width: 24px;
    height: 18px;
}

.info__content {
    display: flex;
    padding-bottom: 12px;
}

.info__content .title {
    flex: 0 1 20%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #1E1E1E;
    width: 123px;
}

.info__content .value {
    flex: 0 1 80%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #828282;
}

.close-io__container {
    display: flex;
    align-items: center;
    height: fit-content;
}

.close-io__container .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;

    color: #1E1E1E;
    padding-right: 10px;
}

.close-io__container .close-io-button {
    background: url(../css/img/closeIo-color.svg) no-repeat center center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.subscribe-decide__loading {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscribe-decide__no-more-records {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
}