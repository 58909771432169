.dashboard__container {
    background: #1E1E1E;
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Roboto;
}

.dashboard__container h2 {
    font-size: 28px;
    line-height: 32px;
    color: #fff;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 32px;
    letter-spacing: -0.7px;
}

.dashboard__kraken-logo {
    min-height: 143px;
    min-width: 160px;
    background: url(../css/img/dashboard-kraken.svg) center no-repeat no-repeat;
}

.insights-container {
    width: 100%;
    max-width: 826px;
    margin-bottom: 6px;
    margin-left: 12px;
}

.tools-container {
    width: 100%;
    max-width: 840px;
}

.insights-container h2 {
    margin-bottom: 32px;
}

.dashboard__items {
    display: flex;
    flex-wrap: wrap;
}

.databases-container {
    max-width: 840px;
}

.databases-container h2 {
    margin-left: 12px;
    margin-bottom: 20px;
}

.insights-links {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    margin: 0 0 15px;
}

.insights-links:last-child {
    margin: 0;
}

.insights-links:hover {
    color: #FFB800;
    text-decoration: underline;
}

.tools-container h2 {
    margin-top: 26px;
    margin-left: 12px;
    margin-bottom: 20px;
}

.dashboard__item {
    padding: 33px 20px;
    width: 144px;
    height: 144px;
    background: #2B2B2B;
    border-radius: 4px;
    margin: 12px;
    text-align: center;
    cursor: pointer;
}

.dashboard__item:hover {
    background: #363636;
}

.dashboard__item .service-name {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
}

.dashboard__item .service-name.name-hover {
    color: #FFB800;
}

.dashboard__image-container {
    margin: 0 auto;
    width: 40px;
    height: 40px;
}

.dashboard__image-container div {
    background-size: 40px 40px;
    width: 100%;
    height: 100%;
}