.kraken-checkbox-root {
  padding: 0 !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.kraken-checkbox-checked {
  color: #FFB800 !important;
}

.kraken-checkbox-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  line-height: 1.46429em;
  margin-left: 30px;
}
.container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #DCDCDC;
  border-radius: 2px;
  background-color: transparent;
}

.languages-filter .checkmark,
.select__popover .checkmark {
  width: 16px;
  height: 16px;
}

.languages-filter__header .container,
.languages-filter .container,
.select__popover .container {
  margin-bottom: 18px;
  padding-left: 16px;
}

.languages-filter .container .checkmark:after,
.select__popover .container .checkmark:after{
  left: 5px;
  top: 2px;
  width: 6px;
  height: 9px;
}

.select-items__item-name {
  line-height: 18px;
}

.checkmark-disable {
  cursor: default;
  background-color: #F5F5F5;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: none;
  opacity: 1;
  background-color: #FFB800;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 10px;
  height: 16px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.loading-container-checkbox {
  display: flex;
  justify-content: center;
}

.display-none {
  display: none !important;
}