.header__container {
    margin: 24px 32px 0;
}

.header__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
}

.header__user {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__user-icon {
    height: 32px;
    width: 32px;
}

.header__title-name-container {
    display: flex;
    align-items: baseline;
}

.edit-icon {
    background: url(../../../css/img/edit-icon.svg) center no-repeat no-repeat;
    width: 16px;
    height: 16px;
    margin: 8px 0 3px 8px;
    cursor: pointer;
    display: inline-block;
}

.header__title-name {
    display: flex;
    flex-direction: column;
}

.header__title-name .title{
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -0.03em;
    text-align: left;
}

.header__title-name .last-update {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.03em;
    color: #828282;
    padding-top: 4px;
    height: 18px;
}

.last-update-text {
    line-height: 18px;
}

.header__title-name .count-emails {
    margin-top: 10px;
}

.header__title-name .count-emails div {
    margin-bottom: 5px;
}

.header__title-name .emails-count__last-update {
    height: 61px;
}

.header__title-name .count-emails div:last-child {
    margin-bottom: 0;
}

.header__title-health {
    display: flex;
}

.header__container .header__title {
    height: auto;
    justify-content: flex-start;
}

.header__container .title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.84px;
    display: flex;
}

.header__container .header__title-name .last-update {
    padding-top: 16px;
    height: 100%;
}

.header__container .header__title-name .count-emails div {
    margin: 6px 0 0 0;
}

.header__container .header__title-name .count-emails div .loading,
.header__container .header__title-name .last-update-text div .loading{
    margin: 0;
    width: 18px;
    height: 18px;
    background-size: 28px 28px;
}

.header__container .header__title-name .count-emails {
    margin: 0;
}
