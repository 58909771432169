.export-popup__container {
    width: 416px;
}

.export-popup__buttons {
    display: flex;
}

.export-popup__fields {
    margin-bottom: 24px;
}

.popup-component__description {
    margin-bottom: 16px !important;
}

.popup-component__count-button {
    margin: 0 12px 0 4px;
    padding: 6px 16px;
    border: 1px solid #FFB800;
    border-radius: 2px;
}

.popup-component__button-count-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.380952px;
    color: #1E1E1E;
    cursor: pointer;
}

.popup-component__button:hover {
    background-color: #F0AD02;
}

.popup-component__button.disabled-button {
    background: rgba(30, 30, 30, 0.3);
    cursor: wait;
}

.fields-spinner {
    text-align: center;
}
