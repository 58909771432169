.health-menu__container {
    display: flex;
}

.health-menu__indicator-container {
    position: relative;
}

.header__container .health-menu__indicator-container {
    margin-top: 10px;
    margin-left: 12px;
}

.health-name {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    margin-right: 8px;
}

.health-value {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
}

.health-indicator {
    height: 16px;
    width: 16px;
    background-color: #00C04D;
    border-radius: 50%;
    cursor: pointer;
}

.health-indicator.waiting {
    background-color: #B9B9B9 !important;
}

.health-indicator.failed {
    background-color: #ED5454 !important;
}

.health-indicator.warning {
    background-color: #FFD900 !important;
}

.health-popover {
    left: 8px;
}

.health__item {
    display: flex;
    width: max-content;
    align-items: flex-start;
    flex-direction: column;
}

.health__item span {
    font-size: 14px;
    line-height: 18px;
    color: #1E1E1E;
}

.health__item span {
    margin-bottom: 5px;
}

.health__item span:last-child {
    margin-bottom: 0;
}