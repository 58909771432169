.kraken-table__container {
    height: 100%;
    margin-bottom: 32px;
}

.itchio-table .kraken-table-row {
    height: 100%;
}

.itchio-table .kraken-table-td-content {
    height: 100%;
    border-bottom: 0;
}

.itchio-table .kraken-table-td {
    border-bottom: 1px solid #e0e0e0;
}

.itchio-table .td-content-row {
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 16px;
}

.itchio-table .td-content-row:last-child {
    margin-bottom: 0;
}

.kraken-table {
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.kraken-table-scroll {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.kraken-table-scroll::-webkit-scrollbar {
    display: none;
}

.kraken-table-head_hidden {
    display: none;
}

.kraken-table-row {
    height: 50px;
}

.kraken-table-th {
    position: sticky;
    top: 0;
    z-index: 4;
    background-color: #FFFFFF;
    padding: 0;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;
}

.kraken-table-th-content {
    width: 100%;
    height: 50px;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.kraken-table-td {
    padding: 0;
    background-color: #FFFFFF;
}

.kraken-table-td-flex-container {
    display: flex;
    align-items: center;
    align-self: flex-start;
}

.kraken-table-td-content {
    height: 50px;
    display: block;
    padding: 17px 16px;
    width: 100%;
    overflow: hidden;
    white-space: pre;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1875em;
    letter-spacing: normal;
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 400;
    color: #1E1E1E;
}

.fixed-column {
    z-index: 6;
}

.fixed-cell {
    position: sticky;
    z-index: 5;
}

.fixed-cell-divider {
    box-shadow: inset -1px 0 #e0e0e0;
}

.fixed-cell-divider.shadow-fixed-cell > div {
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.10);
}

.kraken-table-foot {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;
    background-color: #FFFFFF;
}

.kraken-table-foot-content {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #828282;
}

.kraken-table-foot-content.nothing:hover {
    color: #828282;
    cursor: initial;
}

.kraken-table-foot-content:hover {
    cursor: pointer;
    color: #FFB800;
}

.header-sort-column .kraken-table-th-content {
    padding: 0;
}

.sort-column {
    width: 100%;
    height: 100%;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    text-align: left;
    cursor: pointer;
}

.sort-column.sort-field {

}

.sort-column-icon {

}

.sort-column-icon.can-sort-icon {

}

.sort-column-icon.asc-icon {
    background: url(../../../css/img/sort/sorting-filled-arrow-up.svg) no-repeat center center;
    width: 18px;
    height: 18px;
}

.sort-column-icon.desc-icon {
    background: url(../../../css/img/sort/sorting-filled-arrow-down.svg) no-repeat center center;
    width: 18px;
    height: 18px;
}