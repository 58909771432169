.item-select__item {
    width: 225px;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #1E1E1E;
    border-bottom: 1px solid #DCDCDC;
    cursor: pointer;
}

.item-select__item:hover {
    background-color: #F5F5F5;
}

.item-select__popover .popover__menu-container{
    padding: 0;
}