.search-input-root {
    position: relative;
    color: #1E1E1E;
    cursor: text;
    display: inline-flex;
    align-items: center;

    box-sizing: border-box;
    height: 40px;
    width: 316px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.search-input-text {

}

.kraken-search-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 12px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    font-size: 14px;
    line-height: 17px;
    text-overflow: ellipsis;
}

.kraken-search-input:focus {
    outline: none;
}

.search-input-root:focus-within {
    border: 1px solid #FFB800;
}

.kraken-search-input::placeholder {
    color: #BBBBBB;
    opacity: 1;
}

.search-input-icon {
    background: url(../../../css/img/search-icon.svg) no-repeat center center;
    min-width: 20px;
    height: 20px;
    margin: 12px;
    cursor: pointer;
}

.search-input-close-icon {
    background: url(../../../css/img/close-icon-gray.svg) no-repeat center center;
    min-width: 16px;
    height: 16px;
    background-size: 16px;
    margin: 12px;
    cursor: pointer;
}