.radio {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
}

.radio.active .radio__label:after {
    transform: scale(1, 1);
}

.radio.active .radio__label:before {
    border: 1px solid #FFB800;
}

.radio__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    opacity: 0;
    z-index: 6;
    margin: 0;
    cursor: pointer;
}

.radio___label {
    display: block;
    padding: 0 0 0 24px;
    cursor: pointer;
}

.radio__label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 1px solid #DCDCDC;
    border-radius: 50%;
    z-index: 1;
}

.radio__label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    background-color: #FFB800;
    border-radius: 50%;
    z-index: 2;
    transform: scale(0, 0);
}
