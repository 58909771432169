.rename-input {

}

.rename-input.rename-input_invalid .rename-input__input {
    border-bottom: 1px solid #ED5454;
}

.rename-input__input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;

    letter-spacing: -0.03em;

    color: #1E1E1E;

    outline: none;
    border: none;
    background: transparent;
    border-bottom: 1px solid #1E1E1E;
    width: 289px;
    padding: 0;
    box-sizing: border-box;
}

.rename-input__popper {
    width: 319px;
    padding: 12px;
    visibility: hidden;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: normal;

    color: #1E1E1E;

    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgb(171 171 171 / 25%);
}

.rename-input__popper .rename-input__popper-arrow {
    position: absolute;
    left: 19px;
    top: 0px;
    z-index: 1;
    background-color: #FFFFFF;
    border-left: 1px solid #DCDCDC;
    border-top: 1px solid #DCDCDC;

    transform: translate(-50%, -50%) rotate(45deg);
    height: 16px;
    width: 16px;
}

.rename-input__popper.rename-input__popper_invalid {
    visibility: visible;
}