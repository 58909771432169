.date-range-popover .popover__menu-container {
  padding: 0;
}

.date-range {
  display: inline-block;
  position: relative;
  z-index: 10;
  width: 248px !important;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table {
  width: 210px !important;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table tr {
  border: 0;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay {
  position: relative;
  padding: 0 !important;
  min-width: 32px !important;
  width: 32px !important;
  max-width: 32px !important;
  height: 32px !important;
  border: none !important;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay:hover:not(.CalendarDay__selected_span):not(.CalendarDay__selected_start):not(.CalendarDay__selected_end) {
  background-color: #FFFFFF;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay:hover .date-range__day {
  border: 1px solid #FFB800;
  border-radius: 15px;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay__blocked_out_of_range:hover .date-range__day {
  border: none;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay:focus {
  outline: none !important;
  outline-color: transparent;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay .date-range__day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay__selected_start {
  background-color: #FFF1CC;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay__selected_span, .date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay__hovered_span {
  color: #1E1E1E;
  background-color: #FFF1CC;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay__selected_end {
  background-color: #FFF1CC;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay__selected_start .date-range__day, .date-range .DayPicker .CalendarMonth .CalendarMonth_table .CalendarDay__selected_end .date-range__day {
  border-radius: 25px;
  background-color: #FFB800;
  margin: 2px;
}
.date-range__debug {
  display: flex;
  flex-direction: column;
}
.date-range__inputs {
  align-items: center;
  border-top: 1px solid #DCDCDC;
  padding: 16px 12px;
  background-color: #fff;
  z-index: 10;
  width: 100%;
}

.date-range__inputs .inputs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  position: relative;
}
.date-range__inputs-header {
  display: flex;
  justify-content: space-between;
}
.date-range__inputs-header p {
  margin: 0;
  color: #1E1E1E;
  font-size: 14px;
  line-height: 18px;
}
.date-range__inputs-header__clear-icon {
  background: url(../../../css/img/clear-date-range.svg) no-repeat center center;
  width: 16px;
  height: 16px;
  margin-top: 1px;
  cursor: pointer;
}
.date-range__inputs-separator {
  width: 8px;
  height: 1px;
  background-color: #424242;
}
.date-range__input-wrapper {
  box-sizing: border-box;
  width: 100px !important;
  border: 0;
  height: 32px;
}
.date-range__input {
  padding-left: 8px !important;
}

.date-range__input-error input {
  border: 1px solid #F44336 !important;
}
.date-range__mask {
  position: absolute;
  width: 100px;
  height: 32px;
  top: 0;
  left: 0;
  font-size: 14px;
  pointer-events: none !important;
}
.date-range__input-error:after {
  content: 'Error content';
  position: absolute;
  height: 16px;
  color: #F00;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 0;
  top: calc(100% + 4px);
  display: flex;
  align-items: center; }
.date-range__input-without-underline:before {
  content: none !important;
}
.date-range__input-without-underline:after {
  content: none !important;
}
.date-range__buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
  border-top: 1px solid #E0E0E0;
}
.date-range__button {
  margin: 12px 0 !important;
  font-size: 16px;
}
.date-range__button-close {
  font-size: 14px;
  line-height: 18px;
  background-color: #fff;
  color: #1E1E1E;
  width: fit-content;
  font-weight: 500;
  padding: 7px 12px;
  text-transform: capitalize;
  height: auto;
}
.date-range__button-close:hover {
  background-color: #F5F5F5;
}
.date-range__button-apply {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 8px !important;
  padding: 7px 16px;
  width: fit-content;
}
.date-range__button-apply {
  padding: 7px 12px;
  width: fit-content;
  height: 100%;
  line-height: 18px;
  margin-left: 8px !important;
  font-size: 14px;
}
.date-range__button-apply:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
