.scrollbars .track-vertical {
    padding: 2px;
    z-index: 10;
    position: absolute;
    width: 11px !important;
    right: 0;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    background-color: transparent; }
.scrollbars .track-vertical .thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    background-color: #adafb3; }

.scrollbars .track-horizontal {
    padding: 2px;
    z-index: 10;
    position: absolute;
    height: 11px !important;
    right: 2px;
    bottom: 0;
    left: 2px;
    border-radius: 3px;
    margin-right: 7px;
    background-color: transparent; }
.scrollbars .track-horizontal .thumb-horizontal {
    position: relative;
    display: block;
    height: 100%;
    cursor: pointer;
    border-radius: 8px;
    background-color: #adafb3; }

.disable-scroll {
    overflow: hidden !important;
    height: 100% !important;
    margin-right: 0px !important; }
