.download-csv__container {
    width: 446px;
    font-family: 'Source Sans Pro', sans-serif !important;
}

.download-csv__title {
    text-align: left;
    margin-bottom: 16px;

    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.888889px;
    color: #212121;
}

.download-csv__description {
    text-align: left;
    margin-bottom: 22px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.380952px;
    color: #212121;

}

.download-csv__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    background: #FFB800;
    border-radius: 4px;
    width: fit-content;
    cursor: pointer;
}

.download-csv__button-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.380952px;
    color: #FFFFFF;
}