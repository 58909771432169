.magnet-decide {

}

.magnet-decide_hidden {
    display: none !important;
}

.magnet-decide__filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
}

.magnet-decide__contacts-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
}

.magnet-decide__filter {
    margin-bottom: 0 !important;
    height: auto;
    margin-left: 24px;
}

.magnet-decide__filter.hidden {
    display: none;
}

.magnet-decide__button.non-close {

}

.magnet-decide__button.non-close .button-root{
    background-color: #ED5454;
}

.magnet-decide__button.next {
    margin-right: 4px;
}

.magnet-decide__button .button-root {
    padding: 0 16px;
    width: auto;
}

.magnet-decide__button.skip {
    margin-right: 24px;
}

.magnet-decide__button.skip .button-root {
    border: 1px solid #FFB800;
    border-radius: 2px;
    color: #1E1E1E;
    background-color: transparent;
}

.magnet-decide__table {
    margin: 27px 0;
}


/*\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/

.magnet-decide__title-message {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    margin-bottom: 16px;
}

.magnet-decide__empty-letter {
    min-height: 455px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    background: #FFFFFF;
}

.magnet-decide__empty-letter_hidden {
    display: none;
}

.magnet-decide__empty-letter-icon {
    background: url(../css/img/empty-letter.svg) no-repeat center center;
    width: 166px;
    height: 166px;
}

.magnet-decide__letter {
    height: 100%;
    background-color: #FFFFFF;
}

.magnet-decide__letter.hidden {
    display: none;
}

.not-found-message {
    padding: 24px;
    min-height: 455px;
    display: flex;
}

.not-found-message .text {
    margin: auto;
}

.magnet-decide__letter-title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 48px;
    padding: 24px;
}

.magnet-decide__vendors-title {
    grid-template-columns: 1fr 1fr 0.4fr;
}

.magnet-decide__letter-title-main {
}

.magnet-decide__letter-body {
    border-top: 1px solid #EDEDED;
    padding: 24px;
}

.magnet-decide__loading {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.magnet-navigation-tabs {
    margin-top: 32px;
}