.magnet-close-buttons {
    display: flex;
}

.magnet-close-button {
    border: 1px solid #FFB800;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 8px;
}

.magnet-close-button__title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.380952px;

    color: #545454;
    padding: 6px 16px;
}