.DayPicker {
    display: inline-block;
    width: 248px !important;
    box-shadow: none !important;
    border-radius: 0 !important; }
.DayPicker > div > div {
    width: 248px !important; }
.DayPicker .DayPicker_transitionContainer {
    width: 248px !important;
    margin-bottom: -20px; }
.DayPicker .DayPicker_transitionContainer .CalendarMonthGrid {
    left: 0 !important; }
.DayPicker .DayPicker_transitionContainer .CalendarMonthGrid .CalendarMonthGrid_month__horizontal {
    width: 248px !important;
    margin-bottom: -23px; }
.DayPicker .DayPickerNavigation_container .DayPickerNavigation_leftButton__horizontal {
    left: 11px !important; }
.DayPicker .DayPickerNavigation_container .DayPickerNavigation_rightButton__horizontal {
    right: 11px !important; }
.DayPicker .DayPickerNavigation_container .DayPickerNavigation_button {
    padding: 0 !important;
    top: 12px !important;
    border-radius: 0 !important;
    border: none; }
.DayPicker .DayPickerNavigation_container .DayPickerNavigation_button:hover {
    background-color: #E0E0E0; }
.DayPicker .DayPickerNavigation_container .DayPickerNavigation_button:hover .DayPickerNavigation_svg__horizontal {
    fill: none; }
.DayPicker .DayPickerNavigation_container .DayPickerNavigation_button:focus {
    outline: none !important;
    outline-color: transparent; }
.DayPicker .DayPicker_weekHeaders {
    margin-left: 0 !important; }
.DayPicker .DayPicker_weekHeaders .DayPicker_weekHeader {
    top: 47px !important;
    border-top: 1px solid #DCDCDC;
    width: 248px !important;
    padding: 0 12px !important;
    padding-top: 8px !important;
    color: #1E1E1E;
    font-size: 12px;
    line-height: 16px;
}
.DayPicker .DayPicker_weekHeaders .DayPicker_weekHeader .DayPicker_weekHeader_ul {
    margin: 0 !important; }
.DayPicker .DayPicker_weekHeaders .DayPicker_weekHeader .DayPicker_weekHeader_ul .DayPicker_weekHeader_li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 32px !important;
    width: 32px !important; }
.DayPicker .CalendarMonth {
    padding: 0 12px !important; }
.DayPicker .CalendarMonth .CalendarMonth_caption {
    padding-top: 12px !important; }
.DayPicker .CalendarMonth .CalendarMonth_caption {
    padding-bottom: 56px !important;
    color: #1E1E1E;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center; }
.DayPickerNavigation_svg__horizontal {
    width: 16px;
    height: 16px;
    background: url(../../../css/img/arrow-calendar.svg) no-repeat center center;
    fill: none;
}

.DayPickerNavigation_rightButton__horizontal .DayPickerNavigation_svg__horizontal {
    transform: scale(-1, 1);
}
