.filters__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.export-dropdown-button {
    flex-direction: row-reverse;
}

.export-dropdown-button.twitter-button {
    margin-right: 16px;
}

.filters__export-dropdown-container {
    width: 142px;
    background: #fff;
    position: absolute;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;
    right: 1px;
    top: 48px;
    display: none;
}

.filters__export-dropdown-container.open {
    display: block;
}

.export-dropdown-item {
    font-size: 14px;
    line-height: 16px;
    color: #1E1E1E;
    padding: 16px 0 16px 18px;
    border-bottom: 1px solid #EDEDED;
    cursor: pointer;
    display: flex;
}

.export-dropdown-item:last-child {
    border-bottom: 0;
}

.export-dropdown-item:hover {
    background: rgba(240, 173, 2, 0.03);
}

.export-icon__all-data {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: url(../../../css/img/crunchbase-all-data.svg) no-repeat center center;
}

.export-icon__twitter {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: url(../../../css/img/crunchbase-twitter.svg) no-repeat center center;
}

.export-icon__emails {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: url(../../../css/img/email-icon.svg) no-repeat center center;
}

.export-icon__linkedin {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: url(../../../css/img/crunchbase-linkedin.svg) no-repeat center center;
}

.export-icon__facebook {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: url(../../../css/img/crunchbase-facebook.svg) no-repeat center center;
}

.filters__export {
    margin-top: 7px;
    margin-left: 12px;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.filters__export.multiple button:last-child {
    margin-right: 8px;
}

.filter-items {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.filter-search {
    margin-top: 24px;
    background-color: #FFFFFF;
    margin-left: auto;
}

.filter__item_wrapper {
    margin-right: 10px;
}

.date-input .select__container .select__input {
    width: 212px;
}

.export-button {
    margin-right: 0;
    margin-left: auto;
}

.search-with-filters-icon {
    width: 220px;
    margin-left: 8px;
}

.select-items__buttons.dropdown-filter-buttons {
    align-items: end;
    margin-left: auto;
    margin-right: 16px;
    grid-column-start: 5;
}

.dropdown-filter-buttons .submit-button {
    margin-left: 8px;
}

.header-export-container {
    position: absolute;
    top: 24px;
    right: 32px;
    display: flex;
}

.header-export-container .filter-search {
    margin: 0;
}

.header-export-container .filters__export {
    margin: 0 0 0 12px;
}

.header-export-container .export-dropdown-button {
    flex-direction: row;
    width: 111px;
    padding: 0 16px;
}

.appstore-filter__container {
    margin-top: 24px;
    margin-bottom: 24px;
}

.appstore-filter__container .add-filter-button__container {
    width: 130px;
    color: #1E1E1E;
    background-color: #fff;
    border: 1px solid #DCDCDC;
}

.appstore-filter__container .add-filter-button__container:hover {
    border: 1px solid #828282;
}

.appstore-filter__container .add-filter-button__container .add-filter-icon {
    background: url(../../../css/img/add-filter-icon.svg) no-repeat center center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

@keyframes openFilters {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes closeFilters {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.filters-container {
    border-radius: 4px;
    width: 100%;
    display: flex;
    opacity: 0;
    flex-wrap: wrap;
    margin-right: -16px;
    position: relative;
    background: #fff;
    transition: opacity 0.16s ease-out;
    height: 0;
}

.filters-container.visible {
    opacity: 1;
    height: auto;
    padding: 16px;
}

.select__input {
    width: 180px;
}

.filter__item_wrapper {
    margin: 12px 12px 0 0;
}

.date-input .select__container .select__input {
    width: 200px;
}

.exit-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    cursor: pointer;
    background: url(../../../css/img/exit-16-hover.svg) no-repeat center center;
}

.selected-filters {
    display: flex;
    height: 0;
    opacity: 0;
    flex-wrap: wrap;
    border-radius: 4px;
    background: #fff;
    position: relative;
    transition: opacity 0.16s ease-out;
}

.selected-filters.visible {
    opacity: 1;
    height: auto;
    padding: 16px 80px 8px 16px;
}

.selected-filter__container {
    border-radius: 4px;
    border: 1px solid #1E1E1E;
    color: #1E1E1E;
    font-size: 14px;
    line-height: 18px;
    padding: 4px 8px;
    width: fit-content;
    margin: 0 8px 8px 0;
    max-width: 680px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.selected-filter__container .filter-name {
    font-weight: 600;
    margin-right: 4px;
}

.selected-filter__container .only-chosen-count-filter {
    color: #828282;
    font-weight: 400;
    margin-left: 3px;
}

.selected-filters-icons {
    display: flex;
    position: absolute;
    right: 16px;
}

.selected-filters-icons .filters-edit-icon {
    width: 24px;
    height: 24px;
    background: url(../../../css/img/edit.svg) no-repeat center center;
    margin-right: 8px;
    cursor: pointer;
}

.selected-filters-icons .filters-close-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url(../../../css/img/exit-16-hover.svg) no-repeat center center;
}

.select-items__buttons {
    align-items: end;
    margin-left: auto;
    margin-top: 24px;
}

.select__popover .select-items__buttons {
    margin-top: 12px;
}

.select__popover.popover-languages-filter .select-items__buttons {
    margin-top: 24px;
}

.popover__container {
    top: 6px;
}

.select__popover.popover-languages-filter .popover__menu-container {
    padding: 32px;
    border-radius: 4px;
}