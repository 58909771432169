.languages-count__input-field {
    width: 90px;
}

.crowdin-number-of-languages .input-root {
    width: 59px;
}

.crowdin-number-of-languages .popover__menu-container {
    padding: 12px;
}

.crowdin-number-of-languages .download-count__input .title {
    margin-right: 16px;
}