.add-input {
    display: flex;
    position: relative;
}

.add-input.add-input_invalid .add-input__input {
    border: 1px solid #ED5454;
}

.add-input__input {
    width: 220px;
    height: 40px;
    background-color: #FFFFFF;
}

.add-input__input .kraken-input::placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #BBBBBB;
}

.add-input__twitter-adornment {
    width: 32px;
    height: 38px;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #828282;
}

.add-input__input-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #828282;

    position: absolute;
    top: -26px;
}

.add-input__button {
    margin-left: 18px;
    width: auto;
    padding: 0 16px;
}

.add-input__button.add-input__button_disable {
    background: rgba(255, 184, 0, 0.5);
    pointer-events: none;
    cursor: default;
}