.select__title {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;
    margin-bottom: 8px;
}

.select__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 204px;
    border-radius: 4px;
    border: 1px solid #DCDCDC;
    background-color: #FFFFFF;
    cursor: pointer;
    padding: 12px 16px;
}

.select__input:hover {
    border: 1px solid #FFB800;
}

.select__input.active {
    border: 1px solid #FFB800;
}

.snovio-filter-container {
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
}

.select__input-value {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E1E1E;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.select__arrow {
    min-width: 8px;
    height: 5px;
}

.select-items__container {
    display: flex;
    flex-direction: column;
}

.steam-genre-popover .select-items__container,
.big-filter-popover .select-items__container {
    width: 752px;
}

.select-items__block {
    display: flex;
    flex-direction: column;
}

.all-checkbox {
    margin-bottom: 8px;
}

.select-items__block-title {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    height: 30px;
}

.select-items__item {
    display: flex;
    align-items: center;
    height: 30px;
    width: 260px;
}

.select-items__item-name {
    margin-left: 8px;
    width: 190px;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.select-items__item-name.disabled {
    color: rgba(30, 30, 30, 0.3);
}

.select-items__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
}

.select-items__buttons .cancel-button {
    padding: 7px 12px;
    width: fit-content;
    font-size: 14px;
    letter-spacing: 0.02em;
    height: auto;
    line-height: 18px;
    white-space: nowrap;
    font-weight: 500;
    background: #FFFFFF;
    color: #1E1E1E;
}

.select-items__buttons .cancel-button:hover {
    background: #F5F5F5;
}

.select-items__buttons .submit-button {
    padding: 7px 12px;
    width: fit-content;
    font-size: 14px;
    height: auto;
    letter-spacing: 0.02em;
    line-height: 18px;
    margin-left: 8px;
}

.select-items__buttons .clear-filters {
    background-color: #FFF;
    border: 1px solid #1E1E1E;
    color: #1E1E1E;
    padding: 10px 16px;
    width: fit-content;
    margin-right: 10px;
}

.select-items__buttons .clear-filters:hover {
    color: #363636;
    border: 1px solid #363636;
    background-color: #F5F5F5;
}

.select-items__buttons .submit-filters {
    background-color: #1E1E1E;
    color: #FFF;
    padding: 10px 16px;
    width: fit-content;
}

.select-items__buttons .submit-filters:hover {
    background-color: #363636;
}

.select-items__buttons .submit-button {
    margin-left: 16px;
}

.select-items__popover .popover__menu-container {
    padding: 32px;
}

.category-options__container {
    display: flex;
}

.common-category {
    height: 616px;
    width: 520px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
}

.locations-select__container {
    width: 1250px;
    flex-wrap: wrap;
    display: flex;
}

.radio-select-options__item.all-radiobutton {
    width: 100%;
    margin-bottom: 8px;
}

.loading-spinner-center {
    width: 100%;
    text-align: center;
}

.language-options__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 540px;
    width: 936px;
}

.language-options__container .select-items__item {
    width: 234px;
}

.language-loading {
    width: 936px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.options-loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.amount-language__container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 24px;
}

.type-language__container {
    display: flex;
    flex-direction: column;
}

.type-language__options-container {
    display: flex;
    height: 64px;
}

.type-language__options-container .radio-button__container{
    width: 107px;
}

.type-language__options-container .radio-button__name{
    font-weight: 600;
}

.amount-language__options-container {
    display: flex;
    justify-content: space-between;
    height: 64px;
}

.language__name {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 16px;
}

.amount-language__name, .type-language__name {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.radio-button__container {
    display: flex;
    align-items: center;
    width: 230px;
}

.radio-button__name {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 8px;
}

.country-options__item {
    display: flex;
    align-items: center;
    height: 32px;
    width: 250px;
}
.country-options__item-title {
    margin-left: 8px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.country-options__container {
    width: 1000px;
    height: 1121px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.country-options__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin: 32px 0 16px;
}

.country-options__search {
    padding-bottom: 32px;
    border-bottom: 1px solid #EDEDED;
}

.country-options__empty {
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio-button__radio-input {
    margin-left: 8px;
    width: 46px !important;
    height: 40px !important;
}

.radio-button__radio-input .kraken-input {
    padding-left: 16px;
}

.epic-category-popover {
    left: -436px;
}

.steam-genre-popover {
    left: -192px;
}

.epic-category-popover .options__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 1000px;
}

.epic-category-popover .select-items__item {
    width: 200px;
}

.epic-price-filter .popover__menu-container{
    right: auto !important;
}

.epic-price-filter .item-select__item{
    width: 160px !important;
}

.steam-genre-popover .options__container,
.big-filter-popover .options__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 270px;
    width: 800px;
}

.steam-genre-popover .select-items__item,
.big-filter-popover .select-items__item {
    width: 184px;
    height: 26px;
    margin-right: 8px;
}

.radio-select-options__item-title {
    margin-left: 8px;
    cursor: pointer;
}

.radio-select-options__item {
    display: flex;
    align-items: center;
    height: 32px;
    width: 250px;
}

.languages-filter__container {
    width: 852px;
    padding: 20px;
}

.languages-filter__header {
    display: flex;
    justify-content: space-between;
    width: 752px;
    position: relative;
}

.languages-filter__header .appstore-filter {
    position: absolute;
    right: 0;
    top: 33px;
    height: 32px;
    width: 220px;
}

.languages-filter__header .appstore-filter > input {
    padding: 7px 12px;
}

.languages-filter__header .appstore-filter .search-input-icon {
    background-size: 18px 18px;
    min-width: 18px;
}

.languages-filter__header .number-languages p {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #1E1E1E;
    margin: 0 0 16px;
}

.languages-only {
    display: flex;
    justify-content: flex-end;
}

.languages-only > span {
    font-size: 14px;
    line-height: 18px;
    color: #828282;
    margin-right: 8px;
}

.languages-only .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 9px;
}

.languages-only .checkmark {
    width: 16px;
    height: 16px;
    top: 1px;
}

.number-languages__inputs {
    display: flex;
    min-width: 154px;
}

.languages-filter__header .number-languages__inputs {
    width: 220px;
}

.number-languages__inputs .number-languages__input-container {
    display: flex;
    width: 50%;
    margin-right: 8px;
    position: relative;
}

.number-languages__inputs .number-languages__input-container:last-child {
    margin: 0;
}

.number-languages__inputs .number-languages__input-focused {
    border: 1px solid #FFB800 !important;
}

.number-languages__inputs .number-languages__input {
    width: 100%;
    height: 32px;
    border-radius: 4px;
}

.number-languages__inputs .number-languages__input .kraken-input {
    text-align: end;
    padding: 7px 12px 6px 0;
    box-sizing: border-box;
    width: calc(100% - 50px);
    margin-left: auto;
    color: #1E1E1E;
}

.number-languages__inputs .title {
    font-size: 14px;
    line-height: 18px;
    color: #828282;
    margin-right: 8px;
    position: absolute;
    left: 12px;
    top: 7px;
    z-index: 1;

}

.number-languages__inputs.inputs-disabled .title {
    color: #BBB;
}

.number-languages__inputs.inputs-disabled .number-languages__input {
    border: 1px solid #DCDCDC !important;
    background: #F5F5F5;
}

.number-languages__inputs.inputs-disabled .kraken-input {
    color: #BBB;
    -webkit-text-fill-color: #bbb;
}

.languages-filter__options {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-right: -16px;
}

.languages-filter .languages-filter__options {
    margin-top: -4px;
}

.languages-filter__options-container {
    min-height: 81px;
    max-height: 341px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -17px;
    padding-right: 17px;
    margin-top: 8px;
}

.languages-filter .select-items__item,
.select__popover .select-items__item {
    width: 184px;
    height: 26px;
    margin-right: 8px;
}

.languages-filter .select-items__item.all-checkbox,
.select__popover .select-items__item.all-checkbox {
    margin-bottom: 0;
}

.languages-filter {
    margin-top: 12px;
    text-align: center;
    overflow: hidden;
}