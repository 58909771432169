.cancel-button-download {
    width: 66px;
    background-color: #fff;
    color: #424242;
}

.cancel-button-download,
.download-count-filter .cancel-button-download {
    padding: 7px 12px;
    width: fit-content;
    height: 100%;
    line-height: 18px;
    font-size: 14px;
}

.download-count-filter .download-count__value {
    width: 180px;
}

.cancel-button-download:hover {
    background-color: #efefef;
}

.submit-button-download {
    width: 96px;
    margin-left: 16px;
}

.submit-button-download,
.download-count-filter .submit-button-download {
    padding: 7px 12px;
    width: fit-content;
    height: 100%;
    line-height: 18px;
    font-size: 14px;
    margin-left: 8px;
}

.download-popover .select-items__buttons {
    margin-top: 16px;
}

.download-popover .popover__menu-container,
.download-count-filter .popover__menu-container,
.select__popover .popover__menu-container {
    padding: 12px;
}

.download-count__input {
    display: flex;
    align-items: center;
}

.download-count__input-container {
    display: flex;
}

.download-count__input .title {
    margin-right: 8px;
}

.download-count__input.from {
    margin-right: 8px;
}

.download-count__input-focused {
    border: 1px solid #FFB800 !important;
}

.download-count__value {
    width: auto;
    max-width: 220px;
}

.download-count__value .select__input-value {
    margin-right: 10px;
}