.magnet-blacklist-button .kraken-table-td-content{
    display: flex;
    align-items: center;
}

.magnet-blacklist-button .button-root {
    width: 83px;
    height: 32px;
    background: #ED5454;
    border-radius: 2px;

    display: none;
}

.kraken-table-row:hover .magnet-blacklist-button .button-root {
    display: block;
}

.magnet-blacklist-button .kraken-table-td-content {
    align-items: center;
    justify-content: center;
    padding: 0;
}

.magnet-blacklist-filter {
    margin-top: 8px;
}