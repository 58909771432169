.subscribe-list__type-select {
    border-top: none;
    border-bottom: 1px solid #DCDCDC;
}

.subscribe-list__search {
    width: 316px;
    height: 40px;
    background-color: #FFFFFF;
}

.subscribe-checkbox-cell .kraken-table-td-content{
    padding: 12px 16px;
}

.subscribe-button-cell .kraken-table-td-content{
    padding: 0;
}

.subscribe-list__count-header {
    display: flex;
    align-items: inherit;
}

.subscribe-list__count-header-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #828282;
    margin-bottom: 20px;
}