.header-with-settings {
    display: flex;
    align-items: center;
}

.header-with-settings__settings-button {
    background: url(../../../css/img/header-settings.svg) no-repeat center center;
    height: 20px;
    width: 20px;
    margin-left: 8px;
    cursor: pointer;
}

.header-with-settings__settings-button_active {
    background: url(../../../css/img/header-settings-active.svg) no-repeat center center;
}

.header-with-settings__settings-button:before {
    content: url(../../../css/img/header-settings-active.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.header-with-settings__settings-button:hover {
    background: url(../../../css/img/header-settings-active.svg) no-repeat center center;
}

.header-with-settings__settings {
    visibility: hidden;

    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.header-with-settings__settings-item {
    width: 225px;
    height: 48px;
    display: flex;
    align-items: center;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #1E1E1E;
    cursor: pointer;
}

.header-with-settings__settings-item:hover {
    background: rgba(240, 173, 2, 0.03);
}

.header-with-settings__settings_display {
    visibility: visible;
    z-index: 10;
}