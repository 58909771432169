.kraken-loader__container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.kraken-loader__gif {
    width: 100px;
    height: 100px;
    background: url(../../../css/img/kraken-loader.gif) center no-repeat no-repeat;
    margin-bottom: 13px;
}

.kraken-loader__app-name {
    width: 91px;
    height: 20px;
    background-size: 91px 20px;
    background-image: url(../../../css/img/app-name-black.svg);
    margin-bottom: 13px;
}