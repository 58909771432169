.twitter-container {

}

.twitter-container .filter-search{
    margin-top: 0;
}

.twitter-container .filters__container {
    margin-top: 53px;
}

.twitter-table {

}

.twitter-table .kraken-table-row:hover .kraken-table-td-flex-container {
    cursor: pointer;
    background: #FFF7E3;
    box-shadow: 0px 2px 0px rgba(71, 51, 0, 0.08);
}

.twitter-table .twitter-table__completed-status-cell {
    background: url(../css/img/check-icon.svg) no-repeat center center;
    width: 15px;
    height: 11px;
}

.twitter-table .kraken-table-row:hover .hover-button-button {
    display: flex;
}

.twitter-button-cell .kraken-table-td-content {
    padding: 0 17px;
}

.twitter-button {
    display: flex;
    align-items: center;
}

.twitter-button__icon {
    background: url(../css/img/twittor-button-icon.svg) no-repeat center center;
    width: 16px;
    height: 17px;
    margin-left: 8px;
}