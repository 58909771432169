.snackbar__container {
    display: none;
    z-index: 1400;
    position: fixed;
    align-items: center;
    justify-content: space-between;

    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    min-width: 288px;

    padding: 12px 16px;
    background-color: rgb(50, 50, 50);
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}

.snackbar__container .title-error {
    max-width: 558px;
    width: calc(100% - 42px);
}

.snackbar__container.position {
    left: 50%;
    right: auto;
    bottom: 24px;
    transform: translateX(-50%);
}

.snackbar__container.content {
    color: #fff;
    flex-grow: 1;
    flex-wrap: wrap;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 4px;
    letter-spacing: 0.01071em;
}

.snackbar__container.show {
    display: flex;
}

.snackbar__container.error {
    background-color: #EF5350 !important; }

.snackbar__container.warning {
    background-color: #FF9800 !important; }

.snackbar__container.success {
    background-color: #4CAF50 !important; }

.close-button {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
}

.close-button:hover {
    background-color: #ee6c69;
    border-radius: 50%;
}

.close-button-icon {
    background: url("../../../css/img/close-icon.svg") no-repeat center center;
    height: 18px;
    width: 18px;
    background-size: 24px;
}

.auth-error__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-error__title {
    width: 250px;
}

.auth-error__button {
    width: 144px;
    height: 40px;
    border: 1px solid #FFFFFF;
    background-color: transparent;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.auth-error__button:hover {
    background-color: transparent;
}

.update-app-snackbar__container {
    width: 580px;
    padding: 23px 60px 23px 24px;
    background: #19AAFC;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.update-app-snackbar__container.content {
    border-radius: 0;
}

.update-app-snackbar__container .button-snackbar {
    padding: 11px 44px 10px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    cursor: pointer;
}

.update-app-snackbar__container .close-snackbar {
    background: url(../../../css/img/exit-update-app-snackbar.svg) no-repeat center center;
    width: 12px;
    height: 12px;
    cursor: pointer;
    position: absolute;
    top: 37px;
    right: 24px;
}