.kraken-link {
  cursor: pointer;
  color: #2196F3 !important;
  text-decoration: none;
  display: inline;
  margin: 0;
  opacity: 1;
}

.kraken-link:hover {
  text-decoration: underline;
}