.twitter-audience__back {
    display: flex;
    align-items: center;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #1E1E1E;

    cursor: pointer;
}

.twitter-audience {
    margin-bottom: -48px;
}

.twitter-audience__back .twitter-audience__back-arrow {
    background: url(../css/img/back-arrow.svg) no-repeat center center;
    width: 8px;
    height: 16px;
    margin-right: 8px;
}

.twitter-audience__title-container {
    margin: 0 32px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.twitter-audience__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.03em;

    color: #828282;
}

.twitter-audience__item {
    padding-bottom: 6px;
}

.twitter-audience__audience-title {
    display: flex;
    align-items: center;
}

.twitter-audience__audience-settings {
    background: url(../css/img/header-settings.svg) no-repeat center center;
    height: 20px;
    width: 20px;
    margin-left: 8px;
    cursor: pointer;
}

.twitter-audience__audience-settings:before {
    content: url(../css/img/header-settings-active.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.twitter-audience__audience-settings:hover {
    background: url(../css/img/header-settings-active.svg) no-repeat center center;
}

.twitter-audience__settings-item {
    display: flex;
    align-items: center;
    padding-left: 18px;
}

.twitter-audience__settings-item .merge {
    background: url(../css/img/merge.svg) no-repeat center center;
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.twitter-audience__settings-item .rename {
    background: url(../css/img/edit.svg) no-repeat center center;
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.twitter-audience__settings-item .delete {
    background: url(../css/img/delete.svg) no-repeat center center;
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.twitter-donors-container .filters__container{
    margin-top: 0;
}

.twitter-remove-button .kraken-table-td-content{
    display: flex;
    align-items: center;
}

.twitter-remove-button .button-root {
    width: 83px;
    height: 32px;
    background: #ED5454;
    border-radius: 2px;

    display: none;
}

.kraken-table-row:hover .twitter-remove-button .button-root {
    display: block;
}

.twitter-remove-button .kraken-table-td-content {
    align-items: center;
    justify-content: flex-start;
    padding: 0;
}