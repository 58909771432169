.navigation-tabs {
    display: flex;
    width: fit-content;
    padding: 4px;
    box-sizing: border-box;

    background: #EDEDED;
    border-radius: 4px;
}

.navigation-tabs__item {
    display: flex;
    align-items: center;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;

    height: 32px;
    padding: 0 20px;
}

.navigation-tabs__item:hover {
    color: #F0AD02;
}

.navigation-tabs__item.navigation-tabs__item_active {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    color: #F0AD02;
}
.navigation-tabs__item.navigation-tabs__item_hidden {
    display: none;
}