.subscribe-success-component__icon {
    background: url(../../../css/img/green-check-icon.svg);
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 10px;
}

.subscribe-success-component__container {
    width: 298px;
}

.subscribe-success-button {
    border: 1px solid #FFB800;
    border-radius: 2px;
    cursor: pointer;
    width: fit-content;
}

.subscribe-success-component__description {
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1E1E1E;
    margin-bottom: 16px;
}