.app-store-icon {
    background: url(./img/app-store.svg) center no-repeat no-repeat;
}

.app-store-icon:before {
    content: url(./img/app-store-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.close-io-icon {
    background: url(./img/closeIo.svg) center no-repeat no-repeat;
}

.close-io-icon:before {
    content: url(./img/closeIo-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.crunchbase-icon {
    background: url(./img/crunchbase.svg) center no-repeat no-repeat;
}

.crunchbase-icon:before {
    content: url(./img/crunchbase-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.eustartups-icon {
    background: url(./img/eustartups.svg) no-repeat center center;
}

.eustartups-icon:before {
    content: url(./img/eustartups-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .eustartups-icon,
.navigation-menu__item.active-route .eustartups-icon {
    background: url(./img/eustartups-yellow.svg) no-repeat center center;
}

.dashboard__item:hover .eustartups-icon {
    background-image: url(./img/eustartups-yellow.svg);
}

.navigation-menu__item.active-route .eustartups-icon:before {
    content: url(./img/eustartups.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.epic-icon {
    background: url(./img/epic.svg) no-repeat center center;
}

.epic-icon:before {
    content: url(./img/epic-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.nintendo-icon {
    background: url(./img/nintendo.svg) no-repeat center center;
}

.nintendo-icon:before {
    content: url(./img/nintendo-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .nintendo-icon,
.navigation-menu__item.active-route .nintendo-icon {
    background: url(./img/nintendo-yellow.svg) no-repeat center center;
}

.dashboard__item:hover .nintendo-icon {
    background-image: url(./img/nintendo-yellow.svg);
}

.navigation-menu__item.active-route .nintendo-icon:before {
    content: url(./img/nintendo.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.google-play-icon {
    background: url(./img/google-play.svg) center no-repeat no-repeat;
}

.google-play-icon:before {
    content: url(./img/google-play-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.steam-icon {
    background: url(./img/steam.svg) center no-repeat no-repeat;
}

.steam-icon:before {
    content: url(./img/steam-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.subscribe-icon {
    background: url(./img/subscribe.svg) center no-repeat no-repeat;
}

.subscribe-icon:before {
    content: url(./img/subscribe-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.twitter-icon {
    background: url(./img/twitter.svg) center no-repeat no-repeat;
}

.twitter-icon:before {
    content: url(./img/twitter-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.magnet-icon {
    background: url(./img/magnet.svg) center no-repeat no-repeat;
}

.magnet-icon:before {
    content: url(./img/magnet-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.crowdin-icon {
    background: url(./img/crowdin.svg) center no-repeat no-repeat;
}

.crowdin-icon:before {
    content: url(./img/crowdin-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.gog-icon {
    background: url(./img/gog-icon.svg) center no-repeat no-repeat;
}

.gog-icon:before {
    content: url(./img/gog-icon-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .gog-icon,
.navigation-menu__item.active-route .gog-icon {
    background: url(./img/gog-icon-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .gog-icon {
    background-image: url(./img/gog-icon-yellow.svg);
}

.navigation-menu__item.active-route .gog-icon:before {
    content: url(./img/gog-icon.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .crowdin-icon,
.navigation-menu__item.active-route .crowdin-icon {
    background: url(./img/crowdin-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .crowdin-icon {
    background-image: url(./img/crowdin-yellow.svg);
}

.navigation-menu__item.active-route .crowdin-icon:before {
    content: url(./img/crowdin.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.snovio-icon {
    background: url(./img/snovio.svg) center no-repeat no-repeat;
}

.snovio-icon:before {
    content: url(./img/snovio-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .snovio-icon,
.navigation-menu__item.active-route .snovio-icon {
    background: url(./img/snovio-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .snovio-icon {
    background-image: url(./img/snovio-yellow.svg);
}

.navigation-menu__item.active-route .snovio-icon:before {
    content: url(./img/snovio.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .app-store-icon,
.navigation-menu__item.active-route .app-store-icon {
    background: url(./img/app-store-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .app-store-icon {
    background-image: url(./img/app-store-yellow.svg);
}

.navigation-menu__item.active-route .app-store-icon:before {
    content: url(./img/app-store.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .close-io-icon,
.navigation-menu__item.active-route .close-io-icon {
    background: url(./img/closeIo-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .close-io-icon {
    background-image: url(./img/closeIo-yellow.svg);
}

.navigation-menu__item.active-route .close-io-icon:before {
    content: url(./img/closeIo.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .crunchbase-icon,
.navigation-menu__item.active-route .crunchbase-icon {
    background: url(./img/crunchbase-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .crunchbase-icon {
    background-image: url(./img/crunchbase-yellow.svg);
}

.navigation-menu__item.active-route .crunchbase-icon:before {
    content: url(./img/crunchbase.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .google-play-icon,
.navigation-menu__item.active-route .google-play-icon {
    background: url(./img/google-play-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .google-play-icon {
    background-image: url(./img/google-play-yellow.svg);
}

.navigation-menu__item.active-route .google-play-icon:before {
    content: url(./img/google-play.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .steam-icon,
.navigation-menu__item.active-route .steam-icon {
    background: url(./img/steam-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .steam-icon {
    background-image: url(./img/steam-yellow.svg);
}

.navigation-menu__item.active-route .steam-icon:before {
    content: url(./img/steam.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .twitter-icon,
.navigation-menu__item.active-route .twitter-icon {
    background: url(./img/twitter-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .twitter-icon {
    background-image: url(./img/twitter-yellow.svg);
}

.navigation-menu__item.active-route .twitter-icon:before {
    content: url(./img/twitter.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .magnet-icon,
.navigation-menu__item.active-route .magnet-icon {
    background: url(./img/magnet-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .magnet-icon {
    background-image: url(./img/magnet-yellow.svg);
}

.navigation-menu__item.active-route .magnet-icon:before {
    content: url(./img/magnet.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .subscribe-icon,
.navigation-menu__item.active-route .subscribe-icon {
    background: url(./img/subscribe-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .subscribe-icon {
    background-image: url(./img/subscribe-yellow.svg);
}

.navigation-menu__item.active-route .subscribe-icon:before {
    content: url(./img/subscribe.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .epic-icon,
.navigation-menu__item.active-route .epic-icon {
    background: url(./img/epic-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .epic-icon {
    background-image: url(./img/epic-yellow.svg);
}

.navigation-menu__item.active-route .epic-icon:before {
    content: url(./img/epic.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.itch-icon {
    background: url(./img/itch.svg) center no-repeat no-repeat;
}

.href-icon:before {
    background: url(./img/itch-yellow.svg) center no-repeat no-repeat;
}

.navigation-menu__item:hover .itch-icon,
.navigation-menu__item.active-route .itch-icon {
    background: url(./img/itch-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .itch-icon {
    background-image: url(./img/itch-yellow.svg);
}

.navigation-menu__item.active-route .itch-icon:before {
    background: url(./img/itch.svg) center no-repeat no-repeat;
}

.meet-to-match-icon {
    background: url(./img/meet-to-match.svg) center no-repeat no-repeat;
    background-size: 20px 20px;
}

.meet-to-match-icon:before {
    background: url(./img/meet-to-match-yellow.svg) center no-repeat no-repeat;
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .meet-to-match-icon,
.navigation-menu__item.active-route .meet-to-match-icon {
    background: url(./img/meet-to-match-yellow.svg) center no-repeat no-repeat;
    background-size: 20px 20px;
}

.dashboard__item:hover .meet-to-match-icon {
    background-image: url(./img/meet-to-match-yellow.svg);
}

.navigation-menu__item.active-route .meet-to-match-icon:before {
    background: url(./img/meet-to-match.svg) center no-repeat no-repeat;
}

.href-icon {
    background: url(./img/href.svg) center no-repeat no-repeat;
}

.href-icon:before {
    content: url(./img/href-yellow.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.navigation-menu__item:hover .href-icon,
.navigation-menu__item.active-route .href-icon {
    background: url(./img/href-yellow.svg) center no-repeat no-repeat;
}

.dashboard__item:hover .href-icon {
    background-image: url(./img/href-yellow.svg);
}

.navigation-menu__item.active-route .href-icon:before {
    content: url(./img/href.svg);
    width: 0;
    height: 0;
    visibility: hidden;
}

.logout-icon {
    background: url(./img/logout.svg) center no-repeat no-repeat;
}

.down-arrow-icon {
    background: url(./img/down-arrow.svg) center no-repeat no-repeat;
}

.down-arrow-white-icon {
    background: url(./img/down-arrow-white.svg) center no-repeat no-repeat;
}

.dropdown-export-icon {
    background: url(./img/dropdown-export-icon.svg) center no-repeat no-repeat;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}