.subscribe-component__container {
    width: 539px;
}

.subscribe-filter-textarea__container {
    margin-bottom: 16px;
}

.subscribe-filter-textarea__container textarea {
    border: none;
    outline: none;
    resize: none;
    font-family: Source Sans Pro, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.380952px;
    color: #828282;
    width: 100%;
    padding: 0 0 4px 0;
    border-bottom: 1px solid #D9D9D9;
}

.subscribe-filter-textarea__container.invalid textarea {
    border-bottom: 1px solid #ED5454 !important;
}

.subscribe-filter-textarea__container textarea:focus {
    border-bottom: 1px solid #1E1E1E;
}

.subscribe-buttons {
    display: flex;
}

.subscribe-close-button {
    border: 1px solid #FFB800;
    border-radius: 2px;
    cursor: pointer;
    margin: 0 16px 0 8px;
}

.subscribe-close-button__title {
    font-family: Source Sans Pro, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.380952px;

    color: #545454;
    padding: 6px 16px;
}

.crunchbase-filter__popover .popover__menu-container {
    padding: 0;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-shadow: 0 5px 7px rgba(171, 171, 171, 0.25);
    width: 310px;
}

.crunchbase-filter__popover.popover__container {
    top: -40px;
}

.crunchbase-filter__popover .popover__arrow {
    right: 273px;
}

.crunchbase-filter__error-message {
    padding: 13px 11px 11px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1E1E1E;
}