.button-root {
    width: 144px;
    height: 40px;

    display: inline-flex;
    border: 0;
    margin: 0;
    border-radius: 4px;
    background-color: #FFB800;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
}

.button-root:hover {
    background-color: #F0AD02;
}

.button-root.disabled {
    background-color: rgba(255, 184, 0, 0.5);
    cursor: default;
    pointer-events: none !important;
}

.button-text {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}