.legend {
    position: relative;
    width: fit-content;
}

.legend__icon {
    background: url(../../../css/img/legend-icon.svg) no-repeat center center;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.legend-popover .popover__menu-container{
    left: -12px;
}

.legend-popover .popover__arrow{
    left: 8px;
}